import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types";

import Button from "../custom-widgets/button";

/**
 * @typedef {Object} Cards
 * @property {string} id - Required string id of the card.
 * @property {string} cardClass - optional string for the classes of the div container.
 * @property {string} url - optional url in case we want to use an INTERNAL LINK and give a stretched-link to the card.
 * @property {string} anchorUrl - optional url in case we want to use an EXTERNAL LINK and give a stretched-link to the card.
 * @property {string} titleClass - optional classes for the h3 green title.
 * @property {string} title - optional h3 green text title of the card.
 * @property {string} textClass - optional `<p>` classes.
 * @property {string} text - optional `<p>` text below the title of the card.
 * @property {Object} button - optional object for <Button /> at the bottom of the card.
 * @property {Object} image - optional object with the data for the card image.
 * @property {GatsbyImageData} image.imgVariable - required for image - Gatsby image data.
 * @property {string} image.altText - required for image - string image alt text.
 */

/**
 * This is the component to render the no link cards in a row, these are cards with button links inside and not clickable cards.
 *
 * @param {Object} props - The object containing props.
 * @param {string} props.sectionId - required string.
 * @param {string} props.sectionClass - optional section container classes like "bg-light".
 * @param {string} props.rowColsClass - optional `<ul>` element container classes like "row-cols-1 row-cols-md-2 row-cols-lg-3".
 * @param {string} props.titleClass - optional classes for h2 section title above the cards.
 * @param {string} props.title - optional h2 section title above the cards.
 * @param {boolean} props.hasGrowEffect - optional boolean value to add the class "grow" to the card div container.
 * @param {boolean} props.hasRiseShadowEffect - optional boolean value to add the class "rise-shadow" if true or "shadow" if false to the card div container.
 * @param {Object} props.button - optional object for <Button /> at the bottom of the card.
 * @param {string} props.textAlignClass - optional classes for the div container of the link below the cards outside the row.
 * @param {string} props.linkClass - optional classes for the link below the cards outside the row.
 * @param {string} props.link - optional internal link path to go for the link below the cards outside the row.
 * @param {string} props.linkText - optional text to show for the link below the cards outside the row.
 * @param {string} props.linkId - optional id for the link below the cards outside the row.
 * @param {Cards[]} props.cards - required Array of objects.
 *   - `id`: required string id of the card.
 *   - `cardClass`: optional string for the classes of the div container.
 *   - `url`: optional url in case we want to use an INTERNAL LINK and give a stretched-link to the card.
 *   - `anchorUrl`: optional url in case we want to use an EXTERNAL LINK and give a stretched-link to the card.
 *   - `titleClass`: optional classes for the h3 green title.
 *   - `title`: optional h3 green text title of the card.
 *   - `textClass`: optional `<p>` classes.
 *   - `text`: optional `<p>` text below the title of the card.
 *   - `button`: optional object for <Button /> at the bottom of the card.
 *   - `image`: optional object with the data for the card image.
 *   - `image.imgVariable`: required for image - Gatsby image data.
 *   - `image.altText`: required for image - string image alt text.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const NoLinkCards = ({
  sectionId = "link-cards-section",
  sectionClass = "bg-light",
  rowColsClass = "row-cols-1 row-cols-md-2 row-cols-lg-3",
  titleClass = "",
  title = "",
  hasGrowEffect = false,
  hasRiseShadowEffect = false,
  useMobileView = false,
  button = {},
  textAlignClass = "text-sm-center",
  linkClass = "font-weight-bold",
  link = "",
  linkText = "",
  linkId = "",
  cards = [
    {
      id: "link-card-1",
      cardClass: "col mb-4",
      url: "",
      anchorUrl: "",
      image: {
        imgVariable: null,
        altText: ""
      },
      titleClass: "",
      title: "CARES Program",
      textClass: "",
      text: "Small Business Loan & Paycheck Protection",
      button: {}
    },
    {
      id: "link-card-2",
      cardClass: "col mb-4",
      url: "",
      anchorUrl: "",
      image: {
        imgVariable: null,
        altText: ""
      },
      titleClass: "",
      title: "Coronavirus Mortgage Assistance",
      textClass: "",
      text: "Defer payments for three months",
      button: {}
    },
    {
      id: "link-card-3",
      cardClass: "",
      url: "",
      anchorUrl: "",
      image: {
        imgVariable: null,
        altText: ""
      },
      titleClass: "",
      title: "Refinance your Mortgage",
      textClass: "",
      text: "Take advantage of low rates",
      button: {}
    }
  ]
}) => {
  const growClass = hasGrowEffect ? "grow" : "";
  const shadowClass = hasRiseShadowEffect ? "rise-shadow" : "shadow";

  const desktopClass = useMobileView === true ? "d-none d-md-flex" : "";

  return (
    <section id={sectionId} className={sectionClass}>
      <div className="container">
        {title && <h2 className={titleClass}>{title}</h2>}
        <div className={`row ${desktopClass} ${rowColsClass}`}>
          {cards.map((card, idx) => (
            <div id={card.id} key={idx} className={`col mb-4 mb-lg-0 ${card.cardClass}`}>
              <div
                className={`card h-100 position-relative border-radius-12 overflow-hidden card-box-shadow ${shadowClass} ${growClass}`}
              >
                {card.image && card.image.imgVariable && (
                  <GatsbyImage
                    image={card.image.imgVariable}
                    alt={card.image.altText}
                    className="card-image-top border-radius-12 border-radius-bottom-0"
                  />
                )}
                <div className="card-body d-flex flex-column align-items-start">
                  {card.title && (
                    <h3 className={`card-title ${card.titleClass}`} dangerouslySetInnerHTML={{ __html: card.title }} />
                  )}
                  {card.text && (
                    <p className={`card-text ${card.textClass}`} dangerouslySetInnerHTML={{ __html: card.text }} />
                  )}
                  {card.anchor && card.anchor.text && (
                    <Link to={card.anchor.url} id={card.anchor.id}>
                      {card.anchor.text}
                    </Link>
                  )}
                  {card.button && card.button.text && <Button {...card.button} />}
                </div>
                {card.url && (
                  <Link to={card.url} className="stretched-link">
                    <span className="sr-only">{card.title}</span>
                  </Link>
                )}
                {card.anchorUrl && (
                  <AnchorLink to={card.anchorUrl} className="stretched-link">
                    <span className="sr-only">{card.title}</span>
                  </AnchorLink>
                )}
              </div>
            </div>
          ))}
        </div>

        {button && button.text && <Button {...button} />}
        {link && linkText && (
          <div className={textAlignClass}>
            <Link id={linkId} to={link} className={linkClass}>
              {linkText}
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};
export default NoLinkCards;

NoLinkCards.propTypes = {
  sectionId: PropTypes.string.isRequired,
  sectionClass: PropTypes.string,
  rowColsClass: PropTypes.string,
  titleClass: PropTypes.string,
  title: PropTypes.string,
  hasGrowEffect: PropTypes.bool,
  hasRiseShadowEffect: PropTypes.bool,
  useMobileView: PropTypes.bool,
  button: PropTypes.object,
  textAlignClass: PropTypes.string,
  linkClass: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  linkId: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      cardClass: PropTypes.string,
      url: PropTypes.string,
      anchorUrl: PropTypes.string,
      image: PropTypes.shape({
        imgVariable: PropTypes.object.isRequired,
        altText: PropTypes.string.isRequired
      }),
      titleClass: PropTypes.string,
      title: PropTypes.string,
      textClass: PropTypes.string,
      text: PropTypes.string,
      button: PropTypes.object
    })
  ).isRequired
};
